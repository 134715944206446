<template>
  <form @submit.prevent="onSubmit()">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>
          <div>
            <div v-for="(parameter) in parameters" :key="parameter.id">
              <div class="entity-property entity">
                <div class="entity-property--name"><span>{{ parameter.designation }}</span></div>
                <div class="entity-property--value">
                  {{ parameterValue(parameter) }} {{ parameterValue(parameter) ? parameter.precisions : '' }}
                </div>
                <div class="entity-property--actions">
                  <Btn
                    v-if="helperService.userHasPermission('entiteparametre_edit')"
                    icon="create"
                    round
                    grow
                    default
                    :to="{
                      name: 'myEntityParameterEdit',
                      params: {
                        id: parameter.id
                      }
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </div>
  </form>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'MyEntityParameterView',

  components: {
    Btn,
    Container,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      parameters: [],
      entityParameters: [],
    }
  },

  mounted() {
    this.getParameter()
    this.getEntityParameter()
  },

  methods: {
    getParameter() {
      this.fetchService.get('dictionnaire/parametrage?limit=0&sort=designation.ASC').then((response) => {
        this.parameters = response.data
      })
    },

    getEntityParameter() {
      this.fetchService.get(`entite/${this.$store.state.auth.user.data.entite.id}/parametre`)
        .then((response) => {
          this.entityParameters = response.data
        })
    },

    parameterValue(parameter) {
      const param = this.entityParameters.find(
        (entityParameter) => entityParameter.uid === parameter.uid,
      )

      if (param !== undefined) {
        try {
          const valeur = JSON.parse(param.valeur)
          if (typeof (valeur) === 'object' && valeur && 'value' in valeur) {
            return valeur.value
          }
          return valeur
        } catch (e) {
          console.log(e)
        }
      }

      return ''
    },

  },
}
</script>

<style lang="scss" scoped>
// TODO Nico
.entity {
  display: flex;
  border: 1px solid $color-gray-lightest;
  border-radius: $border-radius-base;
}
.entity-property {
  > * {
    flex: 1;
    padding: $gutter-half;
    display: flex;
    align-items: center;
  }
  &--name {
    background: $color-gray-lightest;
  }
  &--actions {
    justify-content: flex-end;
  }
}
</style>
